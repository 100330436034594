module.exports = {
  defaultTitle: 'Valentin Despa',
  logo: '#',
  author: 'Valentin Despa',
  url: 'https://vdespa.com',
  legalName: 'Valentin Despa',
  defaultDescription: 'I am a dedicated agile software developer and open source lover',
  socialLinks: {
    twitter: 'http://www.x.com/vdespa',
    github: 'https://github.com/vdespa',
    linkedin: 'https://www.linkedin.com/in/vdespa/',
    instagram: 'https://instagram.com/vdespa',
  },
  googleAnalyticsID: '',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'appId',
    twitter: '@vdes[a',
  },
  address: {
    city: 'City',
    region: 'Region',
    country: 'Country',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'email',
    phone: 'phone number',
  },
  foundingDate: '2018',
};
